<template>
    <div class="background-1 page-height">
        <v-container class="my-cars-container-header">
            <div class="row align-center">
                <div class="col col-xs-12 col-md-12 pb-0 pt-6 my-cars-title">
                    <span class="content-title">Welcome back! {{this.currentUser.details.name}}</span>
                </div>
            </div>
        </v-container>
    
         <v-container class="page-content-offset my-car-container-body">
            <div class="row">
                <div class="col-12 col-md-6 pt-2" v-for="(item, i) in items" :key="i">
                    <router-link :to="'/mycar/item/detail?id=' + item.id" class="link-deco">
                    <v-card class="my-cars-background">
                        <div class="row pb-0">
                            <div class="col col-md-7 col-xs-4 pb-0">
                                <v-avatar rounded="lg" class="my-car-avatar-size">
                                    <v-img :src="item.img"></v-img>
                                </v-avatar>
                            </div>
                            <div class="col col-md-5 col-xs-8 my-cars-detail-position">
                                    <div>
                                        <v-card-text class="car-model">{{item.brand}}<br>{{item.model}}</v-card-text>
                                        <v-card-text class="car-plate ma-auto">
                                            {{item.registrationNo}}
                                        </v-card-text>
                                    </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-0 my-cars-update">
                                 <span class="my-cars-update-text" v-if="item.hasUpdate">We have some updates for your car</span>
                            </div>
                        </div>
                    </v-card>
                    </router-link>
                </div>
            </div>                       
        </v-container>
    </div>
</template>

<script>
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, uploadPath, uploadTypeCar } from "/src/constants/config";
import { mapActions } from "vuex";

export default {
    mixins: [currentUserMixin],
    data() {
        return {
            items: [],
        }
    },

    components: {
    },
  
    mounted() {
        this.getCarItems();		
    }, 

    methods: {
       ...mapActions(["signOut"]),
        getCarItems(){
            let url = openRoadApiUrl + "/api/services/" + version + "/car/me/list";
            axios.get(url, this.$data.apiHeaders)
                .then(response => this.setCarItems(response))
                .catch(error => this.onError(error));
        },

        setCarItems(response) {
            this.items = response.data.result.items.map(function(item){
                let obj = {};

                obj['registrationNo'] = item.registrationNo;
                obj['dateRegistration'] = item.dateRegistration;
                obj['brand'] = item.brand;
                obj['model'] = item.model;
                obj['yearMake'] = item.yearMake;
                obj['isActive'] = item.isActive;
                obj['id'] = item.id;
                obj['coverPhoto'] = item.coverPhoto;
                obj['img'] = item.coverPhoto
                            ? uploadPath + "/" + uploadTypeCar + "/thumb/" + item.coverPhoto
                                : "";
                obj['hasUpdate'] = item.hasUpdate;

                return obj;
            });
        },

        onError(error){

            console.log(error.response);
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                    ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                    : "";

            if(error.response.status == 401) this.logout(message, details);
            else {
              this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
              });
            }
        },

        logout(message, details) {
          this.signOut().then(() => {
              this.$router.push("/login");
          });
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
